import React from 'react';
import {InfoSec,Heading, Subtitle,BoldText} from './InfoSection.elements'
import { Container } from '../../globalStyles'

const PrivacyPolicyCN = () => {
    return (
        <InfoSec lightBg={true}>
            <Container>
                <Heading>用户协议</Heading>
                <Subtitle>欢迎您使用 BBscore 的产品及相关服务！</Subtitle>
                <Subtitle>为了使用BBscore 的服务，您应阅读并遵守《BBscore 隐私政策》（以下简称“本协议”）。请仔细阅读并充分理解每一条款，尤其是那些免除或限制责任的条款，以及某些服务的单独协议。限制和免责事项可能以粗体形式突出显示。除非您已阅读并接受本协议的所有条款，否则您无权使用arabg241@gmail.com 提供的服务。您对上述服务的使用被视为您承认并同意受上述协议约束。</Subtitle>
                <Heading>1、协议范围</Heading>
                <Subtitle>1.1 本协议是您与BBscore 就用户使用（“使用”指包括但不限于下载、安装、启动、浏览、注册、登录等行为，以下简称“使用”）BBscore 提供的相关服务所订立的协议。 “BBscore ”指的是arabg241@gmail.com 及其相关的服务相关经营单位。“本站”指的是BBscore 的网站和客户端。“用户”指的是相关服务的用户，在本协议中称为“您”。注册和非注册用户在本协议条款下都被视为“用户”。未注册BBscore 产品和/或服务的用户自使用BBscore 产品和/或服务的瞬间起成为BBscore 的“非注册用户”，并必须遵守本协议中的所有条款，但不包括注册用户专属的条款。</Subtitle>
                <Subtitle>1.2 本协议适用于BBscore 的所有产品和/或服务，包括但不限于音视频服务、广告服务、互动娱乐、会员服务和/或BBscore 提供的其他产品和/或服务。</Subtitle>
                <Subtitle>1.3 在您使用本站的特定服务时，可能存在单独的协议、相关的业务规定等（统称为“单独协议”）。一旦这些单独协议正式发布，它们成为本协议的组成部分，您也有义务遵守。当用户使用BBscore 提供的特定服务时，其使用被视为同意该特定服务的单独协议以及BBscore 在该特定服务中发布的各种公告。</Subtitle>

                <Heading>2、账号与密码安全</Heading>
                <Subtitle>2.1 使用BBscore 时，您需要注册一个账户。</Subtitle>
                <Subtitle>2.2 BBscore 特别提醒您要妥善管理您的账户和密码安全。使用后，您应当安全退出。由于您管理不善可能导致账户被盗或密码丢失，因此由您独自负责。</Subtitle>
                <Subtitle>2.3 BBscore 为您提供了注销账户的权利。您可以在线申请注销，或联系BBscore 客户服务，也可以通过其他公开宣布的方法注销您的账户（除非法律法规或本协议另有规定）。一旦您注销账户，将无法再使用BBscore 的服务，并自动放弃现有的权利。请谨慎操作。除非法律法规另有规定，注销后，BBscore 将停止提供服务，您通过该账户使用的产品和服务下的所有内容、信息、数据和记录将被删除或匿名化。</Subtitle>
                <Subtitle>2.3.1 点击个人资料，点击注销账号，然后确认，所有资料将会从系统里删除</Subtitle>
                <Subtitle>2.4 TAC 使用：</Subtitle>
                <Subtitle>2.4.1 鉴别：TAC 是发送到您注册手机号的六位（6）数字代码，用于交易鉴别。</Subtitle>
                <Subtitle>2.4.2 安全访问：TAC 的设计目的是增强您交易的安全性。请保持您的 TAC 机密，不要与任何人分享。</Subtitle>

                <Heading>3、用户个人信息的保护</Heading>
                <Subtitle>3.1 保护用户个人信息是BBscore 的基本原则。</Subtitle>
                <Subtitle>3.2 在注册账户或使用BBscore 服务的过程中，您可能需要提供一些必要的信息。如果在国家法律法规中有特殊规定，您需要提供真实的身份信息。如果您提供的信息不完整，可能无法使用BBscore 服务或在使用过程中受到限制。</Subtitle>
                <Subtitle>3.3 通常，您可以随时浏览和修改您提交的信息。但是，基于安全和身份识别的考虑（例如号码申诉服务），您可能无法修改注册时提供的初始注册信息和其他验证信息。</Subtitle>
                <Subtitle>3.4 BBscore 将采用各种安全技术和程序建立完善的管理体系，以防止您的个人信息被未经授权的访问、使用或披露。</Subtitle>
                <Subtitle>3.5 在未经您同意的情况下，BBscore 不会向新爱体育以外的任何公司、组织或个人披露您的个人信息，除非法律法规另有规定。</Subtitle>
                <Subtitle>3.6 BBscore 高度重视未成年人个人信息的保护。如果您未满18岁，使用BBscore 服务前应获得您的父母或法定监护人（以下简称“监护人”）的事先同意。监护人应引导未成年人注意在线安全问题，防范问题的发生。如果监护人同意让未成年人使用BBscore 服务，他们必须以监护人的名义申请消费（如涉及），并正确引导和监督未成年人使用BBscore 服务。未成年人使用BBscore 服务，以及根据本协议行使和履行权利和义务，被视为已获得监护人的同意。</Subtitle>
                <Subtitle>3.7 有关个人信息处理和保护规则、用户对个人信息的控制等更多信息，请访问本站产品隐私政策的完整文本。</Subtitle>

                <Heading>4. 服务以现状提供</Heading>
                <Subtitle>4.1 您理解并同意BBscore 的服务是基于现有技术和条件提供的。BBscore 将尽最大努力向您提供服务，确保服务的连续性和安全性。然而，BBscore 无法始终预见和防范由不可抗力引起的风险，包括但不限于自然灾害、病毒、木马、黑客攻击、罢工、政府行动，这可能导致服务中断、数据丢失和其他损失。</Subtitle>
                <Subtitle>4.2 您承认并同意，由于带宽和网络连接问题，BBscore 在提供产品和/或服务过程中可能会出现长时间的缓冲、延迟或类似情况。这些情况不是BBscore 的过错，BBscore 对此不负责。为了减少或避免此类情况，并进一步提高用户体验，您承认BBscore 可能采用各种技术手段优化产品和/或服务。</Subtitle>

                <Heading>5. 自备设备</Heading>
                <Subtitle>5.1 您应理解，在使用BBscore 的服务时，您需要准备与服务相关的终端设备（如计算机、调制解调器等），并承担相关费用（如电话费、互联网费等）。</Subtitle>
                <Subtitle>5.2 您理解并同意，使用BBscore 的服务可能会消耗您终端设备和带宽等资源。</Subtitle>


                <Heading>6.第三方提供的产品或服务</Heading>
                <Subtitle>在BBscore 平台上使用第三方提供的产品或服务时，除遵守本协议的规定外，您还应遵守第三方的用户协议。在法律和协议定义的范围内，BBscore 和第三方对可能发生的争议负责。</Subtitle>

                <Heading>7. BBscore 服务中的软件</Heading>
                <Subtitle>7.1 在使用BBscore 服务的过程中，您可能需要下载软件。对于此类软件，BBscore 向您授予个人、不可转让和非排他性的许可。您只能出于访问或使用BBscore 服务的目的而使用此软件。</Subtitle>
                <Subtitle>7.2 为了提高用户体验，确保服务安全性，并保持产品功能的一致性，BBscore 可能会更新软件。您应该将相关软件更新到最新版本，BBscore 不对其正常使用做出保证。</Subtitle>

                <Heading>8. 知识产权声明</Heading>
                <Subtitle>8.1 BBscore 服务提供的信息内容（包括但不限于网页、文本、图片、音频、视频、图表等）的知识产权属于BBscore 。这不包括用户在使用BBscore 服务之前已经获得合法知识产权的用户发布的信息。</Subtitle>
                <Subtitle>8.2 除非另有说明，BBscore 服务依赖的软件的版权、专利权和其他知识产权属于BBscore 。</Subtitle>
                <Subtitle>8.3 BBscore 服务使用的商业标识的版权或商标权属于BBscore 。</Subtitle>
                <Subtitle>8.4 BBscore 合法拥有的其他知识产权受到法律保护。未经BBscore 的书面许可，您不得以任何形式使用或创建相关衍生作品。</Subtitle>
                <Subtitle>8.5 除非法律另有规定，未经BBscore 的书面授权，您和/或任何第三方不得以任何方式非法复制、复制、引用、链接、抓取或使用本站点的信息内容的全部或部分；否则，BBscore 有权追究您的法律责任。</Subtitle>
    

                <Heading>9. 用户行为准则</Heading>
                <Subtitle>9.1 在使用BBscore 服务时，您必须遵守法律法规，不得参与非法活动，包括但不限于：</Subtitle>
                <Subtitle>(1) 发布、传播、存储危害国家安全、破坏社会稳定、违反公共秩序和道德、侮辱、诽谤、淫秽、暴力或违反任何国家法律法规的内容。</Subtitle>
                <Subtitle>(2) 发布、传播、存储侵犯他人合法权益的内容，如知识产权和商业机密</Subtitle>
                <Subtitle>(3) 恶意捏造事实，掩盖真相以误导或欺骗他人。</Subtitle>
                <Subtitle>(4) 发布、传播、存储广告信息和垃圾信息。</Subtitle>
                <Subtitle>(5) 从事有损BBscore 或第三方声誉和利益的活动。</Subtitle>
                <Subtitle>(6) 使用具有非法、淫秽、侮辱或攻击性含义的昵称或签名。</Subtitle>
                <Subtitle>(7) 其他法律法规禁止的行为。</Subtitle>
                <Subtitle>9.2 如果您违反本规定，相关国家机关或机构可能采取法律行动，对您进行罚款或其他惩罚措施，并可能请求BBscore 的协助。如果造成损害，您有义务依法赔偿，BBscore 不承担责任。</Subtitle>
                <Subtitle>9.3 如果BBscore 发现或收到他人举报您发布的信息违反本规定，BBscore 有权独立判断并使用技术手段删除、屏蔽或断开链接。此外，基于用户行为的性质，BBscore 有权采取包括但不限于暂停或终止服务、限制、冻结或终止使用BBscore 账户等措施，并追究法律责任。</Subtitle>
                <Subtitle>9.4 如果您违反本规定并给任何第三方造成损害，您将独自负责；如果BBscore 遭受损失，有权向您追偿。</Subtitle>


                <Heading>10. 遵守当地法律法规</Heading>
                <Subtitle>10.1 在使用BBscore 服务的过程中，您必须遵守当地法律法规，尊重当地道德风俗。如果您的行为违反当地法律、法规或道德风俗，您将独自负责。</Subtitle>
                <Subtitle>10.2 由于您使用BBscore 服务，应避免将BBscore 卷入政治和公共事件；否则，BBscore 有权暂停或终止对您的服务。</Subtitle>

                <Heading>11. 用户生成的内容和处理第三方投诉</Heading>
                <Subtitle>11.1 您充分理解并同意BBscore 提供的平台，仅供用户上传、分享、传播和获取信息。通过该平台传播或经由该平台传播的内容不反BBscore 的观点或政策，BBscore 对此不承担责任。您对通过BBscore 服务发送或传播的内容，包括但不限于网页、文字、图片、音频、视频、图表等，负有责任。</Subtitle>
                <Subtitle>11.2 通过在本站点上传、发布或传输图像、文本、音频、视频或其他内容，您被视为是上述内容的版权所有人或合法授权人。除非双方另有约定，您公开发布、传播和分享在本站点上述内容的行为代表您授予BBscore 在全球范围内、永久、不可撤销、免费</Subtitle>
                <Subtitle>11.3 通过在此网站上传、发布或传输图像、文字、音频、视频或其他内容，您被视为是上述内容的版权所有人或合法授权人。除非双方另有约定，您公开发布、传播和分享此网站上述内容的行为表示您授予BBscore 全球、永久、不可撤销、免费的权利，行使所有版权权利（包括但不限于复制、分发、租赁、展览、表演、放映、广播、拍摄、改编、翻译、编制您的内容以及与商业开发、推广和服务相关的权利，包括再许可和权利保护），但不包括版权所有者的个人权利。</Subtitle>
                <Subtitle>11.4 您承认并同意，除非法律法规另有要求，BBscore 没有存储您上传、发布或分享的内容的义务，您应自行备份。如果相关法律法规要求，BBscore 将监控您上传到平台的内容。您还承认并同意，除非法律法规另有要求，BBscore 无需对保持此类内容的机密性负责，无论您提供的内容是否为公开已知。</Subtitle>
                <Subtitle>11.5 如果BBscore 收到权利持有人的通知，声称您发送或传播的内容侵犯了他们的相关权利，您同意BBscore 有权独立判断并采取删除、封锁或断开链接等措施。</Subtitle>
                <Subtitle>11.6 如果您违反本条款并损害任何第三方的合法权益（包括但不限于专利权、商标权、著作权及邻接权、肖像权、隐私权、声誉权等），您应该独自负责。如果您的违规行为导致对BBscore 或其合作单位的任何投诉、举报、查询、索赔、诉讼或对BBscore 或其合作单位的声誉、威望或财务造成任何损失，您应采取一切可能的措施确保新爱体育及其合作单位不受此类索赔或诉讼的影响。同时，由于此事造成的BBscore 及其合作单位的一切直接和间接经济损失，您应负全部责任。</Subtitle>


                <Heading>12.生效和协议的修改</Heading>
                <Subtitle>12.1 您使用BBscore 服务被视为您已阅读本协议并接受了本协议的约束</Subtitle>
                <Subtitle>12.2 BBscore 有权在必要时修改本协议的条款。您可以在相关服务页面查看协议条款的最新版本。</Subtitle>
                <Subtitle>12.3 在修改本协议条款后，如果您继续使用BBscore 提供的软件或服务，则视为您已接受修改后的协议。如果您不接受修改后的协议，您应停止使用BBscore 提供的软件或服务。</Subtitle>

                <Heading>13. 变更、中断和终止服务</Heading>
                <Subtitle>13.1 BBscore 可能会更改服务内容，并且还可能中断、暂停或终止服务。</Subtitle>
                <Subtitle>13.2 在以下任何情况下，BBscore 有权在未通知您的情况下中断或终止向您提供的服务：</Subtitle>
                <Subtitle>(1) 根据法律要求，您应提交真实信息，但您提供的个人信息不真实或与注册时提供的信息不一致，且您未能提供合理的证明。</Subtitle>
                <Subtitle>(2) 您违反相关法律法规或本协议的规定。</Subtitle>
                <Subtitle>(3) 根据法律要求或监管机构的要求。</Subtitle>
                <Subtitle>(4) 出于安全原因或其他必要情况。</Subtitle>
                <Heading>14. 其他事项</Heading>
                <Subtitle>14.1 鉴于在线行业的快速多元发展，您与BBscore 之间本协议规定的条款无法全面列举和涵盖您与BBscore 的所有权利和义务。现有的协议也无法保证充分符合未来发展的需求。因此，BBscore 在其网站上公示的相关声明、政策、规则和协议均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如果您使用与本协议相关的服务成为BBscore 会员，则视为您同意上述补充协议。</Subtitle>
                <BoldText><center>Copyright 2024, BBscore, All Rights Reserved</center></BoldText>
            </Container>
        </InfoSec>
    );
  }
  
  export default PrivacyPolicyCN