import React from 'react';
import {InfoSec,Heading, Subtitle , BoldText , List , LineText} from './InfoSection.elements'
import { Container } from '../../globalStyles'

const PrivacyPolicyPT = () => {
    return (
        <InfoSec lightBg={true}>
            <Container>
            <Heading>Política de Privacidade</Heading>
                <Subtitle>Por favor, note que o texto em vermelho no conteúdo precisa ser substituído pelos mais recentes [nome do produto] e [endereço de e-mail] [nome da empresa].</Subtitle>
                <Subtitle>Preambulo Sport Chitz é um produto fornecido por indivíduos (doravante referidos como "nós"). Ao usar nossos serviços, podemos coletar e usar suas informações relevantes. Através desta Política de Privacidade, gostaríamos de explicar como coletamos, usamos, armazenamos e compartilhamos essas informações ao usar nossos serviços, e como fornecemos acesso, atualização, controle e proteção dessas informações para você. Esta Política de Privacidade está intimamente relacionada ao serviço BBscore que você está utilizando. Pedimos que você leia atentamente e, quando necessário, faça escolhas apropriadas de acordo com as instruções desta Política de Privacidade. Termos técnicos relacionados nesta Política de Privacidade são explicados de forma sucinta, com links para mais informações, para facilitar a compreensão.</Subtitle>
                <Subtitle>Ao usar ou continuar a usar nossos serviços, significa que você concorda com a coleta, uso, armazenamento e compartilhamento de suas informações de acordo com esta Política de Privacidade.</Subtitle>
                <Subtitle>Se você tiver alguma dúvida sobre esta Política de Privacidade ou assuntos relacionados, entre em contato conosco através de hkcasper2002@gmail.com.</Subtitle>
                
                
                <Subtitle>1.Informações que Coletamos Quando fornecemos serviços por nós ou por nossos parceiros terceirizados, podemos coletar, armazenar e usar as seguintes informações relacionadas a você. Se você não fornecer essas informações, pode não ser possível se registrar como nosso usuário, desfrutar de alguns de nossos serviços ou alcançar os efeitos pretendidos dos serviços.</Subtitle>
                <Subtitle>Informações pessoais que você nos fornece ao se registrar em uma conta ou ao usar nossos serviços, como número de telefone, endereço de e-mail, etc. Informações de log, referentes ao uso de nossos serviços, que o sistema pode coletar através de cookies, identificadores e tecnologias similares, incluindo informações sobre seu dispositivo, atividades de navegação, cliques, e armazena essas informações como registros para fornecer uma experiência de usuário personalizada e garantir a segurança dos serviços. Você pode recusar ou gerenciar o uso de cookies, identificadores ou tecnologias similares através das configurações do seu navegador.</Subtitle>
               
                <Subtitle>2.Armazenamento de Informações </Subtitle>
                <Subtitle>2.1 Método e Prazo de Armazenamento de Informações Nós armazenamos suas informações de forma segura, incluindo armazenamento local (por exemplo, cache de dados usando aplicativos), em bancos de dados e logs de servidores. Geralmente, armazenamos suas informações pessoais apenas pelo tempo necessário para cumprir os propósitos do serviço ou conforme exigido por leis e regulamentos.</Subtitle>
                <Subtitle>2.2 Local de Armazenamento de Informações De acordo com as leis e regulamentos, armazenamos as informações pessoais dos usuários coletadas no país onde estão localizados. Atualmente, não realizamos transferências ou armazenamento transfronteiriços de suas informações pessoais. Se no futuro for necessário realizar tais transferências ou armazenamento, informaremos o objetivo da transferência, o destinatário, as medidas de segurança e os riscos envolvidos, obtendo sua autorização prévia. </Subtitle>
                <Subtitle>2.3 Notificação ao Encerrar a Operação do Produto ou Serviço Em caso de encerramento da operação de nossos produtos ou serviços, notificaremos você através de notificações push, anúncios, etc., e excluiremos ou anonimizaremos suas informações pessoais dentro de um prazo razoável, exceto quando exigido por leis e regulamentos.</Subtitle>

                <Subtitle>3.	Segurança da Informação Utilizamos diversas tecnologias e procedimentos de segurança para evitar perdas, uso indevido, acesso não autorizado ou divulgação de informações. Por exemplo, em alguns serviços, utilizamos tecnologia de criptografia (como SSL) para proteger as informações pessoais que você fornece. No entanto, você deve entender que, devido a limitações técnicas e possíveis ameaças maliciosas, na indústria da Internet, mesmo com todos os esforços para reforçar as medidas de segurança, não podemos garantir a segurança de suas informações 100%. Você deve estar ciente de que os sistemas e redes de comunicação que você utiliza para acessar nossos serviços podem enfrentar problemas fora do nosso controle.</Subtitle>
                <Subtitle>4.	Como Usamos as Informações Podemos utilizar as informações coletadas durante a prestação de serviços para os seguintes fins:</Subtitle>
                <Subtitle>Fornecer serviços a você; 
                    Usar para autenticação de identidade, atendimento ao cliente, segurança, prevenção de fraudes, arquivamento e backup, garantindo a segurança dos produtos e serviços que fornecemos a você; Auxiliar no design de novos serviços, melhorando nossos serviços existentes; Entender melhor como você acessa e utiliza nossos serviços para responder de forma personalizada às suas necessidades, como configurações de idioma, configurações de localização, serviços de ajuda e instruções personalizadas, ou para responder a você e a outros usuários de outras maneiras; Fornecer anúncios mais relevantes para você em vez de anúncios genéricos; Avaliar a eficácia de anúncios e outras atividades promocionais e melhorá-las; Certificação de software ou gerenciamento de atualizações de software; Permitir sua participação em pesquisas sobre nossos produtos e serviços.
                    </Subtitle>
                <Subtitle>5.	Compartilhamento de Informações Atualmente, não compartilhamos ativamente nem transferimos suas informações pessoais para terceiros. Se houver necessidade de compartilhar ou transferir suas informações pessoais para terceiros, ou se você precisar que compartilhemos ou transfiramos suas informações pessoais para terceiros, obteremos sua autorização expressa ou confirmaremos diretamente com terceiros sobre seu consentimento para tais ações.</Subtitle>
                <Subtitle>Para fins publicitários, de avaliação e otimização da eficácia da publicidade e outros propósitos, precisamos compartilhar parte de seus dados com anunciantes e seus agentes, bem como outros parceiros terceirizados. Eles devem cumprir rigorosamente nossas medidas e requisitos de proteção de privacidade de dados, incluindo, mas não se limitando a, processar dados de acordo com acordos de proteção de dados, compromissos e políticas de processamento de dados relevantes, e evitar a identificação de identidades pessoais para garantir a segurança da privacidade.</Subtitle>
                <Subtitle>Não compartilharemos informações que possam identificá-lo pessoalmente (como seu nome ou endereço de e-mail) com parceiros, a menos que você nos autorize explicitamente.</Subtitle>
                <Subtitle>Não divulgaremos publicamente as informações pessoais coletadas. Se for necessário fazer uma divulgação pública, informaremos o propósito da divulgação, o tipo de informações divulgadas e quaisquer informações sensíveis envolvidas, obtendo sua autorização expressa.</Subtitle>
                <Subtitle>À medida que nossos negócios continuam a se desenvolver, podemos realizar fusões, aquisições, transferências de ativos, etc. Informaremos sobre essas circunstâncias e, de acordo com as leis, regulamentos e padrões não inferiores aos requisitos desta Política de Privacidade, continuaremos a proteger ou solicitaremos ao novo controlador que continue a proteger suas informações pessoais.</Subtitle>
                <Subtitle>Além disso, de acordo com as leis, regulamentos e padrões nacionais relevantes, podemos compartilhar, transferir ou divulgar informações pessoais sem obter seu consentimento prévio em situações como as seguintes:</Subtitle>
                <Subtitle>Diretamente relacionado com a segurança nacional e a defesa nacional; Diretamente relacionado com a segurança pública, saúde pública e interesses vitais públicos; Diretamente relacionado com investigações criminais, acusações, julgamentos e execuções de sentenças; Para proteger os direitos e interesses legais importantes do titular das informações pessoais ou de outras pessoas, mas é difícil obter o consentimento da pessoa em questão; Quando o titular das informações pessoais divulga as informações pessoais ao público; Coleta de informações pessoais de informações legalmente divulgadas, como relatórios de notícias legais, informações governamentais abertas, etc.</Subtitle>

                <Subtitle>6.	Seus Direitos Durante o uso de nossos serviços, podemos fornecer configurações operacionais específicas para que você possa consultar, excluir, corrigir ou retirar suas informações pessoais relevantes. Consulte os guias específicos correspondentes para realizar operações. Além disso, estabelecemos canais para relatar queixas, e suas opiniões serão tratadas prontamente. Se você não puder exercer seus direitos como titular das informações pessoais através dos métodos e canais acima, entre em contato conosco através das informações de contato fornecidas nesta Política de Privacidade, e forneceremos feedback de acordo com as disposições das leis e regulamentos.</Subtitle>
                <Subtitle>Quando você decidir não usar mais nossos produtos ou serviços, pode solicitar a exclusão de sua conta. Após a exclusão da conta, exceto conforme exigido por leis e regulamentos, excluiremos ou anonimizaremos suas informações pessoais.</Subtitle>
                <Subtitle>7.	Alterações Podemos revisar os termos desta Política de Privacidade conforme necessário. Quando houver alterações, o novo documento será apresentado a você na próxima atualização, juntamente com uma explicação da data de entrada em vigor. Leia cuidadosamente o conteúdo revisado da Política de Privacidade. Se você continuar a usar nossos serviços, entenderemos que você concorda com o processamento de suas informações pessoais de acordo com a Política de Privacidade revisada.</Subtitle>
                <Subtitle>8.	Proteção de Menores Encorajamos os pais ou responsáveis a orientar os menores de dezoito anos a usar nossos serviços. Recomendamos que os menores solicitem o consentimento e a orientação de seus pais ou responsáveis antes de fornecer informações pessoais.</Subtitle>
                <Subtitle>9.	Coleta de Informações de Contas de Mídias Sociais Nossos serviços podem permitir que você faça login ou se conecte usando contas de mídias sociais. Quando você opta por fazer isso, podemos coletar informações do seu perfil de mídia social, incluindo seu nome, foto de perfil, endereço de e-mail e outras informações disponíveis publicamente. Essas informações são usadas para melhorar sua experiência e promover interações sociais dentro de nossa plataforma.</Subtitle>
                <Subtitle>Também podemos solicitar acesso a dados adicionais de mídias sociais, como sua lista de amigos ou relacionamentos sociais, apenas com sua permissão explícita. Essas informações são usadas para recursos sociais em nossos serviços e são limitadas pelas configurações de privacidade que você estabeleceu em sua conta de mídia social.</Subtitle>
                <Subtitle>Não publicaremos ou compartilharemos qualquer informação em suas contas de mídia social sem sua permissão.</Subtitle>

                <Subtitle>10.	Exclusão de Conta Importante:</Subtitle>
                <List>
                    <li><Subtitle>Desinstalar ou excluir o aplicativo não excluirá sua conta.</Subtitle></li>
                    <li><Subtitle>Quando você excluir sua conta, todos os dados relacionados também serão excluídos.</Subtitle></li>
                    <li><Subtitle>Se você registrou seu endereço de e-mail em nossos aplicativos, não poderá mais usá-lo para fazer login.</Subtitle></li>
                    <li><Subtitle>Mesmo que você reinstale os aplicativos, não poderá transferir sua conta, pois os dados não podem ser restaurados de uma conta excluída.</Subtitle></li>
                </List>
                <Subtitle>Para excluir sua conta:</Subtitle>
                <Subtitle>Faça login -> Perfil -> Configurações -> Excluir conta</Subtitle>
                <Heading>Contate-nos</Heading>
                <Subtitle>Se você tiver alguma dúvida sobre nossa Política de Privacidade, entre em contato conosco através de hkcasper2002@gmail.com.</Subtitle>

            </Container>
        </InfoSec>
    );
  }
  
  export default PrivacyPolicyPT