export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'BBscore',
    description:
      'Every Dribble Tells a Story, Live the Unwritten Excitement! Your Smart Sports Sidekick for Instant Event Gratification! ',
    buttonLabel: 'Get Started Now!',
    imgStart: '',
    img: require('../../images/bbscore.png'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Real time Sport News',
    description:
      "Stay in the game with real-time sports news and event updates, ensuring you catch every pivotal moment.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Main Features',
    headline:
      'Event Details',
    description:
      "Instantly fetch event details, schedules, and real-time results.",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img2.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: 'Main Features',
    headline: 'Multiple Sports',
    description:
      'Covering a spectrum of sports and leagues – football, basketball, tennis, baseball, and more.',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Personalized Contents',
    description:
      "Tailored experience – personalized recommendations based on your interests.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
